import React from "react"
import styled from "styled-components"

const TextError = ({ children }) => {
  return (
    <Wrapper>
      <p>{children}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  color: red;
  margin: 0.5rem 0;
`

export default TextError
