import React from "react"
import styled from "styled-components"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { Button } from "@material-ui/core"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TextError from "../components/texterror"

const initialValues = {
  message: "",
  name: "",
  email: "",
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const onSubmit = (values, actions) => {
  console.log(JSON.stringify(values, null, 2))
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({ "form-name": "contact-form", ...values }),
  })
    .then(() => {
      alert("Success")
      actions.resetForm()
    })
    .catch(() => {
      alert("Error")
    })
    .finally(() => actions.setSubmitting(false))
}

const validationSchema = Yup.object({
  message: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
})

const Contact = () => {
  return (
    <Wrapper>
      <SEO title="Contact" />
      <Layout>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form
              name="contact-form"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <Field type="hidden" name="form-name" />
              <Field type="hidden" name="bot-field" />

              <div className="user-msg">
                <h1>Dear Charles,</h1>
                <div className="form-control">
                  <Field
                    as="textarea"
                    name="message"
                    class="message"
                    placeholder="Write your message here..."
                  />
                  <ErrorMessage name="message" component={TextError} />
                </div>
              </div>

              <h1>Best,</h1>
              <div className="user-info">
                <div className="form-control">
                  <Field name="name" placeholder="Your Name" class="field" />
                  <ErrorMessage name="name" component={TextError} />
                </div>
              </div>

              <div className="user-info">
                <div className="form-control">
                  <Field
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    class="field"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </div>
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  p {
    margin: 0;
    padding: 0;
  }

  .user-msg {
    margin-bottom: 40px;
  }

  .message {
    width: 100%;
    height: 260px;
    padding: 14px 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: inherit;
    background-color: inherit;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 1px;
  }

  .user-info {
    margin-bottom: 1rem;
  }

  .field {
    /* margin-bottom: 1rem; */
    width: 100%;
    padding: 14px 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: inherit;
    background-color: inherit;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 1px;
  }

  .error {
    color: red;
  }

  .hidden {
    display: none;
  }
`

export default Contact
